<template>
    <v-card class="mx-auto" max-width="600">
        <v-card-title class="justify-center">
            {{ $t("auth.register") }}
        </v-card-title>
        <v-card-text>
            <v-alert
                v-if="showSendMessage"
                border="left"
                colored-border
                color="success"
                elevation="2"
            >
                {{ $t("auth.setPasswordSuccess") }}
            </v-alert>
            <v-form v-else ref="form" @submit.prevent="onSubmit">
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="email"
                            :label="$t('users.user.email')"
                            prepend-icon="mdi-at"
                            :rules="[
                                emailRule.required(email),
                                emailRule.email(email),
                                emailRule.errors(this.errors),
                            ]"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-0">
                    <v-col>
                        <v-checkbox
                            v-model="CGUCheckbox"
                            :rules="checkboxRules"
                            required
                        >
                            <template v-slot:label>
                                <router-link
                                    :to="{ name: 'TermsOfUse' }"
                                    class="black--text"
                                >
                                    {{ $t("auth.registerCGUCheckbox") }}
                                </router-link>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center">
                        <v-btn
                            color="primary"
                            type="submit"
                            :loading="isLoading"
                        >
                            {{ $t("button.submit") }}
                        </v-btn>
                        <p>
                            <router-link
                                class="primary--text text-decoration-none"
                                :to="{ name: 'Login' }"
                            >
                                {{ $t("auth.registerToLogin") }}
                            </router-link>
                        </p>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            email: "",
            CGUCheckbox: false,

            isLoading: false,
            errors: {},

            showSendMessage: false,

            checkboxRules: [
                (value) => !!value || this.$t("auth.needRegisterCGUCheckbox"),
            ],
        };
    },

    computed: {
        ...mapState({
            homePage: (state) => state.auth.homePage,

            emailRule: (state) => state.utils.emailRule,
        }),
    },

    watch: {
        email() {
            delete this.errors.email;
            this.email = this.email.toLowerCase();
        },
    },

    mounted() {
        this.$recaptchaLoaded().then(() => {
            this.$recaptchaInstance.showBadge();
        });
    },

    methods: {
        onSubmit() {
            if (this.$refs.form.validate()) {
                this.isLoading = true;
                this.errors = {};

                this.$recaptchaLoaded().then(() => {
                    this.$recaptcha("login").then((token) => {
                        this.$store
                            .dispatch("auth/register", {
                                email: this.email,
                                CGUCheckbox: this.CGUCheckbox,
                                captcha: token
                            })
                            .then(() => {
                                this.showSendMessage = true;
                                this.isLoading = false;
                            })
                            .catch((err) => {
                                if (
                                    err !== undefined &&
                                    err.response !== undefined &&
                                    err.response.status == "422"
                                ) {
                                    this.errors = err.response.data;
                                }
                                this.isLoading = false;
                                this.$refs.form.validate();
                            });
                    });
                });
            }
        },
    },

    beforeDestroy() {
        this.$recaptchaInstance.hideBadge();
    },
};
</script>
